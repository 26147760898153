import React from "react";
import emailjs from "@emailjs/browser";

const currentMonth = new Date().getMonth() + 1;
const currentDate = new Date().getDate();

const List = ({ people }) => {
  // Check anyone in the current month
  const isMonthInTheList = people.some((element) => {
    if (element.month === currentMonth) {
      return true;
    }
    return false;
  });

  // If anyone's birthday in the current month and the first day in the each month
  // Conditions match, then send out the email for birthday reminder
  if (isMonthInTheList && currentDate === 1) {
    const serviceID = "service_ucu1o9w";
    const templateID = "template_bmtcl55";
    const templateParams = {
      name: "Star!",
      note: "Someone has a birthday this month.",
      url: "https://birthdays.chenjinxing.fun",
    };
    const publiKey = "ULcB3Cvie8c1wAnTs";

    emailjs.send(serviceID, templateID, templateParams, publiKey).then(
      (response) => {
        console.log("SUCCESS", response.status, response.text);
      },
      (error) => {
        console.log("FAILED...", error.text);
      }
    );
  }
  // Conditions match, then send out the email for birthday reminder
  // Email sent out function ends

  return (
    <>
      {people
        .filter(
          (person) =>
            person.month === currentMonth && currentDate <= person.date
        )
        .map((person) => {
          const { id, name, date, image } = person;
          let dateRemaining = date - currentDate;
          let message = "";

          if (dateRemaining === 0) {
            message = "Today";
          }
          if (dateRemaining === 1) {
            message = dateRemaining + " day later";
          }
          if (dateRemaining > 1) {
            message = dateRemaining + " days later";
          }
          return (
            <article key={id} className="person">
              <img src={image} alt={name} />
              <div>
                <h4>{name}</h4>
                <p>{message}</p>
              </div>
            </article>
          );
        })}
    </>
  );
};

export default List;
