import React, { useState } from "react";
import data from "./data";
import List from "./List";

function refreshPage() {
  window.location.reload(false);
}
function App() {
  const [people] = useState(data);

  return (
    <main>
      <section className="container">
        <h3>This month birthdays</h3>
        <List people={people} />
        <button onClick={refreshPage}>Re-check</button>
      </section>
    </main>
  );
}

export default App;
