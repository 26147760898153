const birthdays = [
  {
    id: 1,
    name: "张丽娜",
    month: 5,
    date: 19,
    image: "/avatar/Lina_Zhang.jpg",
  },
  {
    id: 2,
    name: "Eunice Chen",
    month: 4,
    date: 14,
    image: "/avatar/Eunice_Chen.jpg",
  },
  {
    id: 3,
    name: "Ethan Chen",
    month: 1,
    date: 21,
    image: "/avatar/Ethan_Chen.jpg",
  },
  {
    id: 4,
    name: "陈明",
    month: 1,
    date: 26,
    image: "/avatar/Ming_Chen.jpg",
  },
  {
    id: 5,
    name: "林赛莲",
    month: 3,
    date: 9,
    image: "/avatar/Sailina_Lin.jpg",
  },
  {
    id: 6,
    name: "Lee Chen",
    month: 8,
    date: 18,
    image: "/avatar/Lee_Chen.jpg",
  },
  {
    id: 7,
    name: "Shane Zheng",
    month: 12,
    date: 23,
    image: "/avatar/Xin_Zheng.jpg",
  },
  {
    id: 8,
    name: "Aaron Zheng",
    month: 1,
    date: 18,
    image: "/avatar/Aaron_zheng.jpg",
  },
  {
    id: 9,
    name: "Sylvia Zheng",
    month: 6,
    date: 27,
    image: "/avatar/Slyvia_Zheng.jpg",
  },
  {
    id: 10,
    name: "John Liu",
    month: 3,
    date: 16,
    image: "/avatar/John_Liu.jpg",
  },
  {
    id: 11,
    name: "John Xu",
    month: 12,
    date: 5,
    image: "/avatar/John_Xu.jpg",
  },
  {
    id: 12,
    name: "Ting Tang",
    month: 7,
    date: 24,
    image: "/avatar/Ting_Tang.jpg",
  },
  {
    id: 13,
    name: "Sam Ye",
    month: 12,
    date: 28,
    image: "/avatar/Sam_Ye.jpg",
  },
  {
    id: 14,
    name: "林强",
    month: 8,
    date: 11,
    image: "/avatar/Qiang_Lin.jpg",
  },
  {
    id: 15,
    name: "Andy Ming",
    month: 4,
    date: 17,
    image: "/avatar/Andy_ming.jpg",
  },
  {
    id: 16,
    name: "Chris Chen",
    month: 6,
    date: 5,
    image: "/avatar/Chris_Chen.jpg",
  },
  {
    id: 17,
    name: "Leo Shi",
    month: 3,
    date: 12,
    image: "/avatar/Leo_Shi.jpg",
  },
  {
    id: 18,
    name: "Sophie Dai",
    month: 10,
    date: 25,
    image: "/avatar/Sophie_Dai.jpg",
  },
  {
    id: 19,
    name: "曹美容",
    month: 8,
    date: 11,
    image: "#",
  },
  {
    id: 20,
    name: "张敏生",
    month: 2,
    date: 20,
    image: "#",
  },
  {
    id: 21,
    name: "张镭娜",
    month: 5,
    date: 19,
    image: "/avatar/Leina_Zhang.jpg",
  },
  {
    id: 22,
    name: "陈美芳",
    month: 7,
    date: 29,
    image: "#",
  },
  {
    id: 23,
    name: "林佩菁",
    month: 5,
    date: 1,
    image: "#",
  },
  {
    id: 24,
    name: "林颖",
    month: 10,
    date: 5,
    image: "#",
  },
  {
    id: 25,
    name: "Andy Gao",
    month: 9,
    date: 23,
    image: "/avatar/Andy_Gao.jpg",
  },
  {
    id: 26,
    Name: "Norris Li",
    month: 9,
    date: 28,
    image: "/avatar/Norris_Li.jpg",
  },
  {
    id: 27,
    Name: "奶奶",
    month: 10,
    date: 5,
    image: "#",
  },
  {
    id: 28,
    Name: "Kevin Feng",
    month: 9,
    date: 4,
    image: "/avatar/Kevin_Feng.jpg",
  },
];

export default birthdays;
